.reactMarkDown h2 {
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    padding-bottom: 40px;
}

.reactMarkDown p {
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
    }
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}